import * as React from "react"
import { useState } from "react"
import Helmet from "react-helmet"
import "./mobile-nav.css"
import NavList from "./nav-list"

interface MobileNavProps {
  navigationLinks: { displayName: string; link: string }[]
  isHomePage: boolean
}
const MobileNav: React.FC<MobileNavProps> = ({
  navigationLinks,
  isHomePage,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: isOpen && "mobile-nav-active",
        }}
      />

      <button
        type="button"
        className="mobile-nav-toggle d-lg-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? <i className="bx bx-x" /> : <i className="bx bx-menu" />}
      </button>
      {isOpen && (
        <nav className={"mobile-nav d-lg-none"}>
          <NavList navigationLinks={navigationLinks} isHomePage={isHomePage} isOpen={isOpen} setIsOpen={setIsOpen}/>
        </nav>
      )}
      {isOpen && <div className="mobile-nav-overly"></div>}
    </>
  )
}

export default MobileNav
