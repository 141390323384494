import * as React from "react"

interface NavListProps {
  isHomePage: boolean
  navigationLinks: { displayName: string; link: string }[]
  isOpen?: boolean
  setIsOpen?: (b: boolean) => void
}
const NavList: React.FC<NavListProps> = ({
  isHomePage,
  navigationLinks,
  isOpen,
  setIsOpen,
}) => {
  return (
    <ul>
      {isHomePage ? (
        <li className={"active"}>
          <a href="/">Home</a>
        </li>
      ) : (
        ""
      )}

      {navigationLinks ? (
        navigationLinks.map(nav => (
          <li key={nav.displayName}>
            <a href={`${nav.link}`} onClick={() => setIsOpen ? setIsOpen(!isOpen) : () => {}}>
              {nav.displayName}
            </a>
          </li>
        ))
      ) : (
        <></>
      )}
    </ul>
  )
}

export default NavList
