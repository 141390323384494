import React from "react"
import "./footer.css"
import { useStaticQuery, graphql } from "gatsby"

interface FooterProps {}

const Footer: React.FC<FooterProps> = ({}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            contactDetails {
              phone {
                name
                link
              }
              email {
                name
                link
              }
              address {
                name
              }
            }
          }
        }
      }
    `
  )

  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>SACOFF</h3>
              <p>
                {site.siteMetadata?.contactDetails.address.name}
                <br />
                <br />
                <strong>Phone:</strong>
                <a href={site.siteMetadata?.contactDetails.phone.link}>
                  {" " + site.siteMetadata?.contactDetails.phone.name}
                </a>
                <br />
                <strong>Email:</strong>
                <a href={site.siteMetadata?.contactDetails.email.link}>
                  {" " + site.siteMetadata?.contactDetails.email.name}
                </a>
                <br />
              </p>
            </div>

            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <a href="#hero">Home</a>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>
                  <a href="#about-us">About us</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container d-md-flex py-4">
        <div className="mr-md-auto text-center text-md-left">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>SACOFF</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Developed by <a href="https://yelah.co.za/">Yelah</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
