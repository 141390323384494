import * as React from "react"
import PropTypes from "prop-types"
import "./header.css"
import { Link } from "gatsby"
import MobileNav from "./mobile-nav"
import { useLocation } from "@reach/router"
import NavList from "./nav-list"

const Header = ({ siteTitle, navigationLinks }) => {
  const location = useLocation()
  const isHomePage = location.pathname === "/"
  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo mr-auto">
          <Link to="/">{siteTitle}</Link>
        </h1>

        <MobileNav navigationLinks={navigationLinks} isHomePage={isHomePage} />

        <nav className="nav-menu d-none d-lg-block">
          <NavList navigationLinks={navigationLinks} isHomePage={isHomePage} />
        </nav>

        <Link to="/membership/" className="get-started-btn">
          Membership
        </Link>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
