import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header/header"
import "./layout.css"
import Footer from "./Footer/footer"

interface LayoutProps {
  navigationLinks: { displayName: string; link: string }[]
}
const Layout: React.FC<LayoutProps> = ({ children, navigationLinks }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header navigationLinks={navigationLinks} siteTitle={data.site.siteMetadata?.title || `Home`} />
      {children}
      <Footer />
      <a href="#" className="back-to-top">
        <i className="bx bx-chevron-up"></i>
      </a>
    </>
  )
}

export default Layout
